'use client';

import { useEffect } from 'react';

export default function ProfileWidget({
  name,
  opts,
}: {
  name?: string;
  opts?: any;
}) {
  useEffect(() => {
    if (window && window.Outseta) {
      if (opts) {
        window.Outseta.profile.open(opts);
      }
    } else {
      console.log('Outseta not loaded');
    }
  }, []);
  return <div className={`o-profile-embed w-full ${name}`}></div>;
}
