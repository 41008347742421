'use client';

import { usePathname, useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { usePostHog } from 'posthog-js/react';
import useOutsetaUser from '@/_outseta/useOutsetaUser';

export default function PostHogPageView() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const posthog = usePostHog();
  const userProfile = useOutsetaUser();
  // Track pageviews
  useEffect(() => {
    const isDND = searchParams.get('dnd') === 'true';
    if (pathname && posthog && !isDND) {
      let url = window.origin + pathname;
      if (searchParams.toString()) {
        url = url + `?${searchParams.toString()}`;
      }
      if (userProfile) {
        posthog.identify(userProfile.Uid, {
          email: userProfile.Email,
          name: userProfile.FirstName + ' ' + userProfile.LastName,
        });
      }
      posthog.capture('$pageview', {
        $current_url: url,
      });
    }
  }, [pathname, searchParams, posthog, userProfile]);

  return null;
}

// export function PostHogSessionRecording() {
//   const pathname = usePathname();
//   const searchParams = useSearchParams();
//   const posthog = usePostHog();
//   // Track pageviews
//   useEffect(() => {
//     if (pathname && posthog) {
//       let url = window.origin + pathname;
//       if (searchParams.toString()) {
//         url = url + `?${searchParams.toString()}`;
//       }
//       posthog.startSessionRecording;
//     }
//   }, [pathname, searchParams, posthog]);

//   return null;
// }
