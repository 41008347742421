'use client';

import { useState, useEffect } from 'react';

export default function AuthWidget({
  widgetMode = 'login|register',
}: {
  widgetMode: 'login' | 'register' | 'login|register';
}) {
  const [authCallbackUrl, setAuthCallbackUrl] = useState('');
  const [regConfirmationUrl, setRegConfirmationUrl] = useState('');

  useEffect(() => {
    setAuthCallbackUrl(window.location.origin + '/api/auth');
    setRegConfirmationUrl(window.location.origin + '/login');
  }, []);

  // Only render widget on client
  if (!authCallbackUrl) return null;

  return (
    <div
      className="o-auth-embed w-full"
      data-widget-mode={widgetMode}
      data-authentication-callback-url={authCallbackUrl}
      data-registration-confirmation-url={regConfirmationUrl}
    ></div>
  );
}
