import { UserProfile } from '@/definition/userProfile';
import { useState, useEffect } from 'react';

function useOutsetaUser() {
  const [userProfile, setUserProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    if (window && window.Outseta) {
      window.Outseta.getUser()
        .then((profile: any) => {
          //   console.log(profile);
          setUserProfile(profile);
        })
        .catch((error: any) => {
          console.error('Failed to fetch Outseta user profile:', error);
        });
    }
  }, []);

  return userProfile;
}

export default useOutsetaUser;
